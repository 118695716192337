@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');
.container {
    display: flex; 
    flex-direction: row ;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;

/*    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;*/
}

.fileSys {
    display: flex; 
    flex-direction: row ;
    flex-wrap: wrap;

    width: calc(100% - 20px);
    height: calc(100% - 60px);

    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}


.fileSysNav {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: calc(100%);
    height: calc(20px);

    border-top: black solid 1px;
}

.fileSysNavUp {
    background-color: transparent;
}

.fileSysNavDir {
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;

    width: calc(100% - 27px);
}