@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
body {
  margin: 0;
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  height: 100vh;
  min-width: 1111px;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(24, 1fr [col]);
  grid-template-rows:  repeat(23, 1fr [row]) 1.2fr [bot-row];/*repeat(12, 1fr [row]);*/
  grid-template-areas:
  "A A A A A A A A A A A A A A A A A A A A F F F F" 
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "C C C C C C D D D D D E E E E E E E E E E E E E"
  ;
  
}

.panel-A {
  grid-area: A;
  background-color: white;

  /*for children components*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.panel-B {
  grid-area: B;
  background-color: rgb(255, 255, 255);
}

.panel-C {
  grid-area: C;
  background-color: black; /*rgb(180, 180, 180);*/
  /*for children components*/
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.panel-D { /*panel menu*/
  grid-area: D;
  background-color: rgb(0, 0, 0);
  /*for children components
  display: grid;
  grid-auto-flow: column;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.panel-E { /*panel search*/
  grid-area: E;
  background-color: black;/*rgb(138, 138, 138);*/
}

.panel-F {
  grid-area: F;
  background-color: white;
}

.tinyBtnIcon {
  background-color: transparent;
  padding: 0;
  color: black;
}
.tinyBtnIcon:hover {
  color: rgb(255, 153, 0);
}

.historyBtnIcon {
  z-index: 9;
  background-color: transparent;
  color: grey;

}

.historyBtnIcon:hover {
  color: black;
}
/*.action {
    width: 4%;
}

.SKU {
    width: 7%;
    text-align: center;
}

.cat {
    width: 3%;
    text-align: center;
}

.subcat {
    width: 9%;
    text-align: center;
}

.brand {
    width: 7%;
    text-align: center;
}

.title {
    width: 20%;
    text-align: center;
}

.spec_1 {
    width: 10%;
    text-align: center;
}
.spec_2 {
    width: 10%;
    text-align: center;
}
.spec_3 {
    width: 10%;
    text-align: center;
}

.qty {
    width: 3%;
    text-align: right;
    padding-right: 5px;
}

.unit {
    width: 3%;
    text-align: left;
}*/
.PageButton_master__3vB39 {
    margin-left: 0.3em;
    margin-right: 0.3em;
    padding: 0;
    border: none;
    background: none;

    color: white;
}

.PageButton_slave__3P8qA {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.3em;
    padding: 0;
    border: none;
    background: none;
}

.PageButton_tool__1RY0b {
    border: none;
    background: none;
    padding: 0;
    margin-top: 0.2em;
    
    position: relative;
    left: -1em;
}

.PageButton_clicked__1GXtm {
    font-size: 30px;
    font-family: 'Ma Shan Zheng', cursive;  
}

.PageButton_not_clicked__3cpww {
    font-size: 20px;
    font-family: 'Noto Sans SC', sans-serif;
}

/**/
.PageButton_addOn_active__xVCTX {
    position: relative;
    left: -20px;
}
.PageButton_addOn_notActive__JvFzG {
    position: relative;
    left: -10px;
}

.PageButton_addOn__bOk18 {
    background-color: transparent;
}

.PageButton_addOnIcon_active__2apE1 {
    color: black;
}
.PageButton_addOnIcon_notActive__1bydU {
    color: grey;
}
.PageButton_addOnIcon_active__2apE1:hover {
    color: rgb(255, 196, 0);
}
.SearchPanel_container__1tnuG{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.SearchPanel_searchContainer__oHCUs{
    height: 100%;
    width: 80%;

    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.SearchPanel_searchBox__2sjfe{
    height: 50%;
    width: 90%;
    border: none;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-size: 1.2em;
}

.SearchPanel_searchBtn__2M2E9{
    margin-left: 5px;
    height: 55%;
    width: 10%;
    border-radius: 5px;
    background-color: rgb(0, 225, 255);/*rgb(0, 140, 255);*/
    color: black;
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
}


.SearchPanel_searchBtn__2M2E9:hover{
    color: white;
    background-color: rgb(255, 196, 0);/*rgb(0, 140, 255);*/
}
.SearchPanel_functionContainer__3S70O {
    height: 100%;
    width: 20%;

    padding-left: 10px;
    padding-right: 20px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
}
.SEARCH_master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;  
}

/*.SEARCH_table {
    margin-top: 10px;
    margin-bottom: 10px;
}*/

.PWA_master_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;  
}

.qrPWAimg {
    height: calc( 80% - 20px);
    width: calc( 80% - 20px);
}
/**/
.SEARCH_th_SKU10 {
    width: 7em;
}
.SEARCH_td_SKU10 {
    text-align: center;
}

.SEARCH_th_cat {
    width: 4em;
}
.SEARCH_td_cat {
    text-align: center;
}

.SEARCH_th_brand {
    width: 6em;
}
.SEARCH_td_brand {
    text-align: center;
}

.SEARCH_th_spec1 {
    width: 13em;
}
.SEARCH_td_spec1 {
    padding-left: 10px;
}

.SEARCH_th_spec1 {
    width: 13em;
}
.SEARCH_td_spec1 {
    padding-left: 10px;
}

.SEARCH_th_spec2 {
    width: 13em;
}
.SEARCH_td_spec2 {
    padding-left: 10px;
}

.SEARCH_th_spec3 {
    width: 13em;
}
.SEARCH_td_spec3 {
    padding-left: 10px;
}

.SEARCH_th_qty {
    width: 4em;
}
.SEARCH_td_qty {
    text-align: right;
    padding-right: 10px;
}
.SEARCH_th_unit {
    width: 4em;
}
.SEARCH_td_unit {
    text-align: left;
    padding-left: 10px;
}
/*@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');*/

.DraggableWindow_container__2Azld{
    /*height: 25vh;
    width: 25vw;*/
    background-color: white;/*trans black -> rgba(0, 0, 0, 0.685);*/
    border-style: solid;
    border-width: 3px;
    border-color: black;
    border-radius: 12px 12px 0px 0px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.DraggableWindow_windowBar__GNPg4{
    background-color: rgb(0, 0, 0);
    height: 20px;
    padding-right: 5px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
}

.DraggableWindow_windowDragBar__1vC0N{
    z-index: 9;
    background-color: rgba(255, 0, 0, 0);
    height: 18px;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}
.DraggableWindow_windowTitlePrefix__WVOeW{
    margin-left: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    color: rgb(0, 225, 255);
}
.DraggableWindow_windowTitle__1HbEf{
    margin-left: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    color: white;
}
.DraggableWindow_windowBtn__17uAQ{
    background-color: transparent;
    padding: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.DraggableWindow_windowContent__16WSN{
    /*height: 100%;*/
    width: 100%;
    height: 100%
}

.DraggableWindow_exitBtn__15BUw{
    font-size: 18px;
}

.DraggableWindow_resizeBtn__2-xBh{
    position: absolute;
    bottom: 5px;
    right: 3px;
    font-size: 12px;
}
.DraggableWindow_resizeBtn__2-xBh:hover{
    background-color: rgb(255, 255, 255);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.DraggableWindow_minimizeBtn__1zbhU{
    width: 11.7px
}
/**/
.HISTORY_master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;  
}
/*toggle*/
.ApolloTable_toggler__2RnYt {
    border: none;
    /*background: rgba(255, 255, 255, 0);*/
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    font-weight: bold;
    background-color: black;
    color: white
}
/*keep header row top*/
.ApolloTable_th_in_thead__N9Ooq {
    position: sticky; 
    top: 0px;
    background: rgb(0, 0, 0);
    text-align: center;
    color: white;
}
.ApolloTable_th_in_thead_expander__1wwP8 {
    position: sticky; 
    top: 0px;
    background: black;
    text-align: center;
}
/*keep header row bot*/
.ApolloTable_td_in_tfoot__15Zdi {
    position: sticky; 
    bottom: -1px;
    background: red;
}
/*remove default borders*/
.ApolloTable_table__1j4Yn {
    border-collapse: collapse;
    font-family: 'Inconsolata', monospace;
    font-size: 14px;
}
/*tr in tbody lines*/
.ApolloTable_tr_in_tbody__31xKo{
    border-top: 1px solid rgb(117, 117, 117);
}
/*hover highlight*/
.ApolloTable_tr_in_tbody__31xKo:hover{
    background: rgba(217, 255, 0, 0.678);
}
.ApolloTable_td_in_tbody__257wF:hover{
    background: rgba(0, 255, 255, 0.678);
}
/**/
.ApolloTable_tr_in_tbody_dataAdder__3b-DN {
    border-top: 4px double rgb(117, 117, 117);    
}
.ApolloTable_th_in_tbody_dataAdder__1Flg3 {
    padding-top: 7px;
    padding-bottom: 0px;
}
/*keep footer row bot*/
.ApolloTable_th_in_tfoot__3eOKz {
    position: sticky; 
    bottom: 0px;
    background: rgb(255, 255, 255);
    text-align: center;
}
.ApolloTable_th_in_tfoot_expander__1K30E {
    position: sticky; 
    bottom: 0px;
    background: black;
    text-align: center;
}
/*action buttons*/
.ApolloTable_action_button_group__7GFeC {
    float: left; /* Float the buttons side by side */
}
.FileIcon_container__17B_o {
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 80px;
}

.FileIcon_iconButton__Mhvvd {
    background-color: transparent;
    font-size: 50px;
}

.FileIcon_iconName__6Xjs5 {
    max-width: 70px;
    display: inline-block;
    word-wrap: break-word;

    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
}
.Folder_container__3JRuU {
    display: flex; 
    flex-direction: row ;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;

/*    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;*/
}

.Folder_fileSys__3HTZE {
    display: flex; 
    flex-direction: row ;
    flex-wrap: wrap;

    width: calc(100% - 20px);
    height: calc(100% - 60px);

    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}


.Folder_fileSysNav__3kH-u {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: calc(100%);
    height: calc(20px);

    border-top: black solid 1px;
}

.Folder_fileSysNavUp__1Ygvs {
    background-color: transparent;
}

.Folder_fileSysNavDir__3Zsqx {
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;

    width: calc(100% - 27px);
}
.UserPanel_avatarContainer__1fcaL {
    /*bot bar is roughly 47px*/
    width: 35px;
    height: 35px;
    padding-left: 20px;
    /*for children components*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.UserPanel_avatar__jqZ3g {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.UserPanel_userActionsContainer__1N2lo {
    padding-left: 10px;
    /*for children components*/
    display: flex;
    flex-direction: column;
}

.UserPanel_userDisplayname__SYXag {
    color: white;
    font-size: 11pt;
}

.UserPanel_logOut__3keyO {
    color: white;
    font-size: 9pt;
}
  
.Login_container__2K43I {
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: black;
}

.Login_iconContainer__3l_Q6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login_icon__O9oR_ {
    -webkit-filter: invert(1) contrast(2);
            filter: invert(1) contrast(2);
    width: 15%;
    margin-top: 15%;
    margin-bottom: 15%;
}

.Login_form__2Qv6v {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login_formRow__17ma3 {
    margin-bottom: 10%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.Login_formRowIcon__1PXl3 {
    width: 10%;
}

.Login_formRowInput__1Qx1E {
    margin-left: 1px;

    height: 20px;
    width: 220px;
    background-color: white;

    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    color: black;
    font-weight: 700;
}

.Login_formLoginButton__2ZcE6 {
    margin-top: 2%;
    background-color: transparent;
    color: rgb(0, 225, 255);
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    font-size: 21px;
}

.Login_formLoginButton__2ZcE6:hover{
    color: rgb(255, 196, 0);
    /*font-size: 23px;*/
}

.Login_formLoginButton__2ZcE6:hover:before{
    /*background-color: rgb(255, 196, 0);*//*rgb(0, 140, 255);*/
    color: rgb(255, 196, 0);/*white;*/
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.Login_formLoginButton__2ZcE6:hover:after{
    color: rgb(255, 196, 0);
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.Login_signUpButton__2cBe9 {
    margin-top: 3%;
    background-color: transparent;
    color: rgb(0, 225, 255);
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 15px;
}

.Login_signUpButton__2cBe9:hover{
    color: rgb(255, 196, 0);
    /*font-size: 20px;*/
}


.Login_signUpButton__2cBe9:hover:before{
    /*background-color: rgb(255, 196, 0);*//*rgb(0, 140, 255);*/
    color: rgb(255, 196, 0);/*white;*/
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.Login_signUpButton__2cBe9:hover:after{
    color: rgb(255, 196, 0);
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.Login_formForgotPasswordContainer__3pWo8 {
    position: absolute;
    bottom: 16px;
    color: rgb(0, 225, 255);
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 12px;
}

.Login_formForgotPasswordContainer__3pWo8:hover {
    color: rgb(255, 196, 0);
}

.Huqd_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Huqd_table {
    /*width: 100%;*/
}

.Huqd_th_expander {
    width: 1em;
}
/**/
.Huqd_th_status {
    width: 5em;
}
.Huqd_td_status {
    text-align: center;
}

.Huqd_th_batch_code {
    width: 5em;
}
.Huqd_td_batch_code {
    text-align: center;
}

.Huqd_th_origin {
    width: 7em;
}
.Huqd_td_origin {
    text-align: center;
}

.Huqd_th_departure {
    width: 5em;
}
.Huqd_td_departure {
    text-align: center;
}

.Huqd_th_est_arrival {
    width: 5em;
}
.Huqd_td_est_arrival {
    text-align: center;
}

.Huqd_th_shippingFee {
    width: 7em;
}
.Huqd_td_shippingFee {
    text-align: right;
}

.Huqd_th_ccy {
    width: 2em;
}
.Huqd_td_ccy {
    text-align: left;
}

.Huqd_th_cvr {
    width: 7em;
}
.Huqd_td_cvr {
    text-align: right;
}

.Huqd_th_tariff {
    width: 7em;
}
.Huqd_td_tariff {
    text-align: right;
}

.Huqd_th_inserted {
    width: 10em;
}
.Huqd_td_inserted {
    text-align: center;
}

.Huqd_th_updated {
    width: 10em;
}
.Huqd_td_updated {
    text-align: center;
}
/**/
.Huqd0_th_SKU {
    width: 7em;
}
.Huqd0_td_SKU {
    text-align: center;
}

.Huqd0_td_subcat {
    text-align: center;
}

.Huqd0_th_brand {
    width: 5em;
}

.Huqd0_td_brand {
    text-align: center;
}

.Huqd0_th_title {
    width: 25em;
}

.Huqd0_th_spec1 {
    width: 13em;
}
.Huqd0_td_spec1 {
    padding-left: 10px;
}

.Huqd0_th_spec2 {
    width: 13em;
}
.Huqd0_td_spec2 {
    padding-left: 10px;
}

.Huqd0_th_spec3 {
    width: 13em;
}
.Huqd0_td_spec3 {
    padding-left: 10px;
}

.Huqd0_th_purchased	 {
    width: 4em;
}
.Huqd0_td_purchased	 {
    text-align: right;
    padding-right: 10px;
}

.Huqd0_th_stashing	 {
    width: 4em;
}
.Huqd0_td_stashing	 {
    text-align: right;
    padding-right: 10px;
}

.Huqd0_th_stashed	 {
    width: 4em;
}
.Huqd0_td_stashed	 {
    text-align: right;
    padding-right: 10px;
}

.Huqd0_th_unit {
    width: 4em;
}
.Huqd0_td_unit {
    text-align: left;
    padding-left: 10px;
}
/*action buttons*/
button {
    cursor: pointer; /* Pointer/hand icon */
    border: none;
}

.faBtnCheck {
    background-color: white;
    border-color: white;

    box-shadow: white;
    
}

.faBtnBug {
    background-color: white;
    border-color: white;
}

.faBtnCircleNotch{
    background-color: grey;
    border-color: grey;

}

.aplBtnEdit {
    background-color: rgb(241, 226, 13);
    border-color: rgb(241, 226, 13);
}

.aplBtnSave {
    background-color: green;
    border-color: green;

    color: white;
}

.aplBtnAbort {
    background-color: grey;
    color: white;
}

.aplBtnDelete {
    background-color: red;
    border-color: red;

    color: white;
}

.aplBtnUpdate {
    background-color: rgb(255, 166, 0);
    border-color: rgb(255, 166, 0);

    color: white;
}

.aplBtnInsert {
    background-color: green;
    border-color: green;

    color: white;
}
.Dcb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Dcb_table {
    /*width: 100%;*/
}

.Dcb_th_expander {
    width: 1em;
}
/**/
.Dcb_th_status {
    width: 5em;
}
.Dcb_td_status {
    text-align: center;
}

.Dcb_th_batch_code {
    width: 5em;
}
.Dcb_td_batch_code {
    text-align: center;
}

.Dcb_th_origin {
    width: 7em;
}
.Dcb_td_origin {
    text-align: center;
}

.Dcb_th_departure {
    width: 5em;
}
.Dcb_td_departure {
    text-align: center;
}

.Dcb_th_est_arrival {
    width: 5em;
}
.Dcb_td_est_arrival {
    text-align: center;
}

.Dcb_th_shippingFee {
    width: 7em;
}
.Dcb_td_shippingFee {
    text-align: right;
}

.Dcb_th_ccy {
    width: 2em;
}
.Dcb_td_ccy {
    text-align: left;
}

.Dcb_th_cvr {
    width: 7em;
}
.Dcb_td_cvr {
    text-align: right;
}

.Dcb_th_tariff {
    width: 7em;
}
.Dcb_td_tariff {
    text-align: right;
}

.Dcb_th_inserted {
    width: 10em;
}
.Dcb_td_inserted {
    text-align: center;
}

.Dcb_th_updated {
    width: 10em;
}
.Dcb_td_updated {
    text-align: center;
}
/**/
.Dcb0_th_SKU {
    width: 7em;
}
.Dcb0_td_SKU {
    text-align: center;
}

.Dcb0_td_subcat {
    text-align: center;
}

.Dcb0_th_brand {
    width: 5em;
}

.Dcb0_td_brand {
    text-align: center;
}

.Dcb0_th_title {
    width: 25em;
}

.Dcb0_th_spec1 {
    width: 13em;
}
.Dcb0_td_spec1 {
    padding-left: 10px;
}

.Dcb0_th_spec2 {
    width: 13em;
}
.Dcb0_td_spec2 {
    padding-left: 10px;
}

.Dcb0_th_spec3 {
    width: 13em;
}
.Dcb0_td_spec3 {
    padding-left: 10px;
}

.Dcb0_th_purchased	 {
    width: 4em;
}
.Dcb0_td_purchased	 {
    text-align: right;
    padding-right: 10px;
}

.Dcb0_th_stashing	 {
    width: 4em;
}
.Dcb0_td_stashing	 {
    text-align: right;
    padding-right: 10px;
}

.Dcb0_th_stashed	 {
    width: 4em;
}
.Dcb0_td_stashed	 {
    text-align: right;
    padding-right: 10px;
}

.Dcb0_th_unit {
    width: 4em;
}
.Dcb0_td_unit {
    text-align: left;
    padding-left: 10px;
}
/**/
.DcbH_th_status {
    width: 5em;
}
.DcbH_td_status {
    text-align: center;
}

.DcbH_th_batch_code {
    width: 5em;
}
.DcbH_td_batch_code {
    text-align: center;
}

.DcbH_th_origin {
    width: 7em;
}
.DcbH_td_origin {
    text-align: center;
}

.DcbH_th_departure {
    width: 5em;
}
.DcbH_td_departure {
    text-align: center;
}

.DcbH_th_est_arrival {
    width: 5em;
}
.DcbH_td_est_arrival {
    text-align: center;
}

.DcbH_th_shippingFee {
    width: 7em;
}
.DcbH_td_shippingFee {
    text-align: right;
}

.DcbH_th_ccy {
    width: 2em;
}
.DcbH_td_ccy {
    text-align: left;
}

.DcbH_th_cvr {
    width: 7em;
}
.DcbH_td_cvr {
    text-align: right;
}

.DcbH_th_tariff {
    width: 7em;
}
.DcbH_td_tariff {
    text-align: right;
}

.DcbH_th_inserted {
    width: 10em;
}
.DcbH_td_inserted {
    text-align: center;
}

.DcbH_th_updated {
    width: 10em;
}
.DcbH_td_updated {
    text-align: center;
}
.Lybq_container__3hUBv {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: row;
}
.Lybq_container_form__ZEkoP {
    width: 30%;
    padding-left: 1%;
}
.Lybq_container_book__g90_5 {

    width: 70%;
    overflow-x: auto;
    /*for children components*/
    display: flex;
    flex-direction: column;
    
}
/**/
.Lybq_general_confirm_button__386lC {
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;
}
/**/
.Lybq_font__1TIKC {
    font-family: Inconsolata, monospace;
    font-size: 16px;
}
/*constant*/
.Lybq_hidden__KipDQ {
    visibility: hidden;
}
.Lybq_page-A4__3yVl0 {
    width: 210mm;
    height: 297mm;
    margin: 5mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
/*formats*/
.Lybq_safe_area-A4-2x2__wyEJq {
    height: calc(100% - 20mm);
    width: calc(100% - 20mm);

    padding: 10mm;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.Lybq_label-A4-2x2__2zqDR {
    width: calc(1/2*100%);
    height: calc(1/2*100%);
}
.Lybq_name-A4-2x2__2bbBO {
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: white;

    padding-top: 3%;
    padding-bottom: 1%;
    background-color: black;
}
.Lybq_qr_code-A4-2x2__2W9wZ {
    display: flex;
    flex-direction: column-reverse;
    /*justify-content: center;*/
    align-items: center;
}
.Lybq_qr_img-A4-2x2__1_u8V {
    width: 70%;
    margin-top: 5%;
}
.Lybq_qr_text-A4-2x2__3Xx82 {
    font-size: 28px;
    /*writing-mode: vertical-rl;*/
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: translate(40%, 0%) rotate(180deg);
            transform: translate(40%, 0%) rotate(180deg);
}

.Lybq_safe_area-A4-4x11__1ccmG {
    height: calc(100% - 20mm);
    width: calc(100% - 6mm);

    padding-top: 10mm;
    padding-bottom: 10mm;
    padding-left: 3mm;
    padding-right: 3mm;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-content: flex-start;
}
.Lybq_label-A4-4x11__2cm-_ {
    width: calc(1/4*100%);
    height: calc(1/11*100%);

    background-color: black;

    display: flex;
    justify-content: center;
}
.Lybq_name-A4-4x11__2SXNn {
    text-align: center;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    font-weight: 30;
    font-size: 22px;
    color: white;

    background-color: black;
}
.Lybq_qr_code-A4-4x11__1V_Ye {
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
    align-items: center;
}
.Lybq_qr_img-A4-4x11__1GVD8 {
    padding-left: 1%;
    padding-right: 3%;
}
.Lybq_qr_text-A4-4x11__2v_ix {
    font-size: 14px;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    color: white;
}

/*for printer*/
@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .Lybq_page-A4__3yVl0 {
        margin: 0;
        border: medium none currentColor;
        border: initial;
        border-radius: 0;
        border-radius: initial;
        width: auto;
        width: initial;
        min-height: 0;
        min-height: initial;
        box-shadow: none;
        box-shadow: initial;
        background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
        background: initial;
        page-break-after: always;
    }
    .Lybq_panel-A__rJVCD,
    .Lybq_panel-C__3YKcv,
    .Lybq_panel-D__1U75Y,
    .Lybq_panel-E__V82Lh,
    .Lybq_panel-F__1KQYw,
    .Lybq_panel-B__3SO9f {
        display: none !important;
    }
    .Lybq_Lybq_page-A4__3HJG__2U44N {
        display: block !important;
    }
}
.Rcb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: row;
}
.Rcb_confirm_button {
    width: calc(100% - 20px);
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;
}
.Rcb_container_0 {
    height: 100%;
    width: 50%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Rcb_container_1 {
    height: 100%;
    width: 50%;
    overflow-x: auto;
    overflow-y: auto;
}
.Rcb_table {
    width: 100%;
}

.Rcb_th_expander {
    width: 1em;
}
/**/
.Rcb_th_status {
    width: 5em;
}
.Rcb_td_status {
    text-align: center;
}

.Rcb_th_batch_code {
    width: 5em;
}
.Rcb_td_batch_code {
    text-align: center;
}

.Rcb_th_origin {
    width: 7em;
}
.Rcb_td_origin {
    text-align: center;
}

.Rcb_th_departure {
    width: 5em;
}
.Rcb_td_departure {
    text-align: center;
}

.Rcb_th_est_arrival {
    width: 5em;
}
.Rcb_td_est_arrival {
    text-align: center;
}

.Rcb_th_shippingFee {
    width: 7em;
}
.Rcb_td_shippingFee {
    text-align: right;
}

.Rcb_th_ccy {
    width: 2em;
}
.Rcb_td_ccy {
    text-align: left;
}

.Rcb_th_cvr {
    width: 7em;
}
.Rcb_td_cvr {
    text-align: right;
}

.Rcb_th_tariff {
    width: 7em;
}
.Rcb_td_tariff {
    text-align: right;
}

.Rcb_th_inserted {
    width: 10em;
}
.Rcb_td_inserted {
    text-align: center;
}

.Rcb_th_updated {
    width: 10em;
}
.Rcb_td_updated {
    text-align: center;
}
.CcbH_th_time {
    width: 7em
}
.CcbH_td_time {
    width: 7em
}
.CcbH_th_name {
    width: 5em;
}
.CcbH_td_name {
    text-align: center;
}

.CcbH_th_spot {
    width: 9em;
}
.CcbH_td_spot {
    text-align: center;
}

.CcbH_th_shelf {
    width: 4em;
}
.CcbH_td_shelf {
    text-align: center;
}

.CcbH_th_floor {
    width: 4em;
}
.CcbH_td_floor {
    text-align: center;
}
.CcbH_th_SKU10 {
    width: 7em;
}
.CcbH_td_SKU10 {
    text-align: center;
}

.CcbH_th_qty {
    width: 4em;
}
.CcbH_td_qty {
    text-align: right;
    padding-right: 10px;
}

.CcbH_th_unit {
    width: 4em;
}
.CcbH_td_unit {
    text-align: left;
    padding-left: 10px;
}
.master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}
/**parent*/
.Spzb_th_SKU04 {
    width: 5em;
}
.Spzb_td_SKU04 {
    text-align: center;
}

.Spzb_th_SKU10s {
    width: 2em;
}
.Spzb_td_SKU10s {
    text-align: right;
    padding-right: 10px;
}

.Spzb_th_cat {
    width: 2em;
}
.Spzb_td_cat {
    text-align: center;
}

.Spzb_th_subcat {
    width: 9em;
}
.Spzb_td_subcat {
    text-align: center;
}

.Spzb_th_brand {
    width: 9em;
}
.Spzb_td_brand {
    text-align: center;
}

.Spzb_td_title {
    padding-left: 10px;
}

.Spzb_th_spec1s {
    width: 4em;
}
.Spzb_td_spec1s {
    text-align: right;
    padding-right: 10px;
}

.Spzb_th_spec2s {
    width: 4em;
}
.Spzb_td_spec2s {
    text-align: right;
    padding-right: 10px;
}

.Spzb_th_spec3s {
    width: 4em;
}
.Spzb_td_spec3s {
    text-align: right;
    padding-right: 10px;
}

/**Spzb0*/
.Spzb0_th_SKU10 {
    width: 8em;
}
.Spzb0_td_SKU10 {
    text-align: center;
}

.Spzb0_th_spec1 {
    width: 13em;
}
.Spzb0_td_spec1 {
    padding-left: 10px;
}

.Spzb0_th_spec2 {
    width: 13em;
}
.Spzb0_td_spec2 {
    padding-left: 10px;
}

.Spzb0_th_spec3 {
    width: 13em;
}
.Spzb0_td_spec3 {
    padding-left: 10px;
}

.Spzb0_th_qty {
    width: 4em;
}
.Spzb0_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Spzb0_th_ordered {
    width: 4em;
}
.Spzb0_td_ordered {
    text-align: right;
    padding-right: 10px;
}

.Spzb0_th_unit {
    width: 4em;
}
.Spzb0_td_unit {
    text-align: left;
    padding-left: 10px;
}

.Spzb0_th_msrp {
}
.Spzb0_td_msrp{
    text-align: right;
    padding-right: 10px;
}
.Spzb0_th_keywords {
}
.Spzb0_td_keywords {
    text-align: right;
    padding-right: 10px;
}
/*Spzb1*/
.Spzb1_th_name {
    width: 5em;
}
.Spzb1_td_name {
    text-align: center;
}

.Spzb1_th_spot {
    width: 9em;
}
.Spzb1_td_spot {
    text-align: center;
}

.Spzb1_th_shelf {
    width: 4em;
}
.Spzb1_td_shelf {
    text-align: center;
}

.Spzb1_th_floor {
    width: 4em;
}
.Spzb1_td_floor {
    text-align: center;
}

/**Spzb2*/
.Spzb2_th_SKU10 {
    width: 7em;
}
.Spzb2_td_SKU10 {
    text-align: center;
}

.Spzb2_th_spec1 {
    width: 13em;
}
.Spzb2_td_spec1 {
    padding-left: 10px;
}

.Spzb2_th_spec2 {
    width: 13em;
}
.Spzb2_td_spec2 {
    padding-left: 10px;
}

.Spzb2_th_spec3 {
    width: 13em;
}
.Spzb2_td_spec3 {
    padding-left: 10px;
}

.Spzb2_th_qty {
    width: 4em;
}
.Spzb2_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Spzb2_th_ordered {
    width: 4em;
}
.Spzb2_td_ordered {
    text-align: right;
    padding-right: 10px;
}

.Spzb2_th_unit {
    width: 4em;
}
.Spzb2_td_unit {
    text-align: left;
    padding-left: 10px;
}
/*@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');*/
.Xzsp_form__2_8hS {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.Xzsp_form_row__Gu3Xv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Xzsp_form_column__25uGt {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.Xzsp_font__22ecb {
    font-family: Inconsolata, monospace;
    font-size: 16px;
}

.Xzsp_sku34__URKu8 {
    width: 1em;
}
.Xzsp_sku567890__V-A-M {
    width: 3em;
}
.Xzsp_keywords__1nP10 {
    width: 100%;
}

.Xzsp_form_confirm__2KIsw {
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;

    width: calc(100% - 20px);
}

/**NOTEPAD**/
.Xzsp_notepad__HCa8N {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.Xzsp_textarea__1TZ6N {
    height: calc(100% - 17px);
    width: calc(100% - 4px);
    resize: none;
    border: none;
}
.Xzsp_keywordsList__ebSSP {
    width: 100%;
}
.Xzsp_footer__1JFAD {
    height: 17px;
    width: 100%;
    border-top: black solid 1px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}
.Ckzb_th_expander,
.Ckzb0_th_expander,
.Ckzb1_th_expander,
.Ckzb2_th_expander,
.Ckzb3_th_expander {
    width: 1em;  
}
/**parent*/
.Ckzb_th_spot {
    width: 9em;
}
.Ckzb_td_spot {
    text-align: center;
}
.Ckzb_th_shelfs {
    width: 5em;
}
.Ckzb_td_shelfs {
    text-align: right;
    padding-right: 10px;
}
/**child0*/
.Ckzb0_th_shelf {
    width: 9em;
}
.Ckzb0_td_shelf {
    text-align: center;
}
.Ckzb0_th_floors {
    width: 5em;
}
.Ckzb0_td_floors {
    text-align: right;
    padding-right: 10px;
}
/*child1*/
.Ckzb1_th_floor {
    width: 9em;
}
.Ckzb1_td_floor {
    text-align: center;
}

.Ckzb1_th_holders {
    width: 5em;
}
.Ckzb1_td_holders {
    text-align: right;
    padding-right: 10px;
}
/*child2*/
.Ckzb2_th_name {
    width: 9em;
}
.Ckzb2_td_name {
    text-align: center;
}
/*child3*/
.Ckzb3_th_SKU10 {
    width: 7em;
}
.Ckzb3_td_SKU10 {
    text-align: center;
}

.Ckzb3_th_spec1 {
    width: 13em;
}
.Ckzb3_td_spec1 {
    padding-left: 10px;
}

.Ckzb3_th_spec2 {
    width: 13em;
}
.Ckzb3_td_spec2 {
    padding-left: 10px;
}

.Ckzb3_th_spec3 {
    width: 13em;
}
.Ckzb3_td_spec3 {
    padding-left: 10px;
}

.Ckzb3_th_qty {
    width: 4em;
}
.Ckzb3_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Ckzb3_th_unit {
    width: 4em;
}
.Ckzb3_td_unit {
    text-align: left;
    padding-left: 10px;
}
/**history*/
.CkzbH_th_spot {
    width: 9em;
}
.CkzbH_td_spot {
    text-align: center;
}
.CkzbH_th_shelfs {
    width: 5em;
}
.CkzbH_td_shelfs {
    text-align: right;
    padding-right: 10px;
}
.Ccb_confirm_button {
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;
}
.Ccb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.Ccb_table {
    width: 100%;
}

.Ccb_th_expander {
    width: 1em;
}
/**/
.Ccb_th_spot {
    width: 9em;
}
.Ccb_td_spot {
    text-align: center;
}

.Ccb_th_shelf {
    width: 4em;
}
.Ccb_td_shelf {
    text-align: center;
}

.Ccb_th_floor {
    width: 4em;
}
.Ccb_td_floor {
    text-align: center;
}
.Ccb_th_name {
    width: 5em;
}
.Ccb_td_name {
    text-align: center;
}

.Ccb_th_SKU10 {
    width: 7em;
}
.Ccb_td_SKU10 {
    text-align: center;
}

.Ccb_th_spec1 {
    width: 13em;
}
.Ccb_td_spec1 {
    padding-left: 10px;
}

.Ccb_th_spec2 {
    width: 13em;
}
.Ccb_td_spec2 {
    padding-left: 10px;
}

.Ccb_th_spec3 {
    width: 13em;
}
.Ccb_td_spec3 {
    padding-left: 10px;
}

.Ccb_th_qty {
    width: 4em;
}
.Ccb_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Ccb_th_unit {
    width: 4em;
}
.Ccb_td_unit {
    text-align: left;
    padding-left: 10px;
}
.Tjqd_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Tjqd_table {
    width: 100%;
}

.Tjqd_td_qty {
    text-align: right;
}
.Cgb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Cgb_table {
    width: 100%;
}

.Cgb_th_expander {
    width: 1em;
}
.Cgb_th_shipment {
    width: 1em;
}
.Cgb_th_status {
    width: 5em;
}
.Cgb_th_qty {
    width: 5em;
}
.Cgb_th_cpu {
    width: 5em;
}
.Cgb_th_ccy {
    width: 3em;
}
.Cgb_th_cvr {
    width: 5em;
}

.Cgb_th_supplier {
    width: 5em;
}
.Cgb_th_inserted {
    width: 5em;
}
.Cgb_th_actions {
    width: 5em;
}


.Cgb_td_shipment {
    text-align: center;
}
.Cgb_td_status {
    text-align: center;
}
.Cgb_td_cat {
    text-align: center;
}
.Cgb_td_subcat {
    text-align: center;
}
.Cgb_td_SKU {
    text-align: center;
}
.Cgb_td_qty {
    text-align: right;
}
.Cgb_td_inserted {
    text-align: center;
}
.Cgb_td_updated {
    text-align: center;
}
.Cgb_td_cpu {
    text-align: right;
}
.Cgb_td_ccy {
    text-align: center;
}
.Cgb_td_cvr {
    text-align: right;
}
.Cgb_td_total {
    text-align: right;
}
.Cgb_td_supplier {
    text-align: center;
}
.Hub_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Hub_table {
    /*width: 100%;*/
}

.Hub_th_expander {
    width: 1em;
}
/**/
.Hub_th_status {
    width: 5em;
}
.Hub_td_status {
    text-align: center;
}

.Hub_th_batch_code {
    width: 5em;
}
.Hub_td_batch_code {
    text-align: center;
}

.Hub_th_origin {
    width: 7em;
}
.Hub_td_origin {
    text-align: center;
}

.Hub_th_departure {
    width: 5em;
}
.Hub_td_departure {
    text-align: center;
}

.Hub_th_est_arrival {
    width: 5em;
}
.Hub_td_est_arrival {
    text-align: center;
}

.Hub_th_shippingFee {
    width: 7em;
}
.Hub_td_shippingFee {
    text-align: right;
}

.Hub_th_ccy {
    width: 2em;
}
.Hub_td_ccy {
    text-align: left;
}

.Hub_th_cvr {
    width: 7em;
}
.Hub_td_cvr {
    text-align: right;
}

.Hub_th_tariff {
    width: 7em;
}
.Hub_td_tariff {
    text-align: right;
}

.Hub_th_inserted {
    width: 10em;
}
.Hub_td_inserted {
    text-align: center;
}

.Hub_th_updated {
    width: 10em;
}
.Hub_td_updated {
    text-align: center;
}

/**Hub0*/
.Hub0_th_inserted {
    width: 5em;
}
.Hub0_td_inserted {
    text-align: center;
}

.Hub0_th_subcat {
    width: 5em;
}
.Hub0_td_subcat {
    text-align: center;
    padding-left: 10px;
} 

.Hub0_th_SKU {
    width: 7em;
}
.Hub0_td_SKU {
    text-align: center;
}

.Hub0_th_spec1 {
    width: 13em;
}
.Hub0_td_spec1 {
    padding-left: 10px;
}

.Hub0_th_spec2 {
    width: 13em;
}
.Hub0_td_spec2 {
    padding-left: 10px;
}

.Hub0_th_spec3 {
    width: 13em;
}
.Hub0_td_spec3 {
    padding-left: 10px;
}

.Hub0_th_qty {
    width: 4em;
}
.Hub0_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Hub0_th_unit {
    width: 4em;
}
.Hub0_td_unit {
    text-align: left;
    padding-left: 10px;
}

.Xzsp_container {
    height: 100%;
    width: 100%;
    /*for children components*/
    display: flex;
    flex-direction: row;
}
.Xzsp_container0 {
    height: 100%;
    width: 30%;
    padding-left: 1%;
    padding-right: 1%;

    overflow-x: auto;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Xzsp_container1 {
    height: 100%;
    width: 68%;

    overflow-x: auto;
    overflow-y: auto;
}

.Xzsp_th_SKU04 {
    width: 4em;
}

.Xzsp_td_SKU04 {
    text-align: center;
}

.Xzsp_th_SKU10s {
    width: 3em;
}

.Xzsp_td_SKU10s {
    text-align: right;
    padding-right: 10px;
}

.Xzsp_th_cat {
    width: 4em;
}

.Xzsp_td_cat {
    text-align: center;
}

.Xzsp_th_subcat {
    width: 10em;
}

.Xzsp_td_subcat {
    text-align: center;
}

.Xzsp_th_brand {
    width: 5em;
}

.Xzsp_td_brand {
    text-align: center;
}

.Xzsp_th_title {
    width: 25em;
}

.Xzsp_td_title {
    text-align: left;
    padding-left: 10px;
}

.Xzsp_th_spec1s	 {
    width: 4em;
}

.Xzsp_td_spec1s {
    text-align: right;
    padding-right: 10px;
}

.Xzsp_th_spec2s	 {
    width: 4em;
}

.Xzsp_td_spec2s {
    text-align: right;
    padding-right: 10px;
}

.Xzsp_th_spec3s	 {
    width: 4em;
}

.Xzsp_td_spec3s {
    text-align: right;
    padding-right: 10px;
}

/**/
.Xzsp0_td_id {
    text-align: center;
    padding-left: 10px;
}

.Xzsp0_th_SKU10 {
    width: 7em;
}

.Xzsp0_td_SKU10 {
    text-align: center;
}

.Xzsp0_th_spec1 {
    width: 15em;
}

.Xzsp0_td_spec1 {
    text-align: left;
    padding-left: 10px;
}

.Xzsp0_th_spec2 {
    width: 15em;
}

.Xzsp0_td_spec2 {
    text-align: left;
    padding-left: 10px;
}

.Xzsp0_th_qty {
    width: 4em;
}

.Xzsp0_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Xzsp0_th_ordered {
    width: 4em;
}

.Xzsp0_td_ordered {
    text-align: right;
    padding-right: 10px;
}

.Xzsp0_th_unit {
    width: 4em;
}

.Xzsp0_td_unit {
    text-align: left;
    padding-left: 10px;
}

.Xzsp0_th_msrp {
    width: 4em;
}

.Xzsp0_td_msrp {
    text-align: right;
    padding-right: 10px;
}

.Xzsp0_th_keywords {
}

.Xzsp0_td_keywords {
    text-align: left;
    padding-left: 10px;
}

/**/
@counter-style star {
    system: cyclic;
    symbols: "\1F48E";
    suffix: " ";
}
