.Hub_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Hub_table {
    /*width: 100%;*/
}

.Hub_th_expander {
    width: 1em;
}
/**/
.Hub_th_status {
    width: 5em;
}
.Hub_td_status {
    text-align: center;
}

.Hub_th_batch_code {
    width: 5em;
}
.Hub_td_batch_code {
    text-align: center;
}

.Hub_th_origin {
    width: 7em;
}
.Hub_td_origin {
    text-align: center;
}

.Hub_th_departure {
    width: 5em;
}
.Hub_td_departure {
    text-align: center;
}

.Hub_th_est_arrival {
    width: 5em;
}
.Hub_td_est_arrival {
    text-align: center;
}

.Hub_th_shippingFee {
    width: 7em;
}
.Hub_td_shippingFee {
    text-align: right;
}

.Hub_th_ccy {
    width: 2em;
}
.Hub_td_ccy {
    text-align: left;
}

.Hub_th_cvr {
    width: 7em;
}
.Hub_td_cvr {
    text-align: right;
}

.Hub_th_tariff {
    width: 7em;
}
.Hub_td_tariff {
    text-align: right;
}

.Hub_th_inserted {
    width: 10em;
}
.Hub_td_inserted {
    text-align: center;
}

.Hub_th_updated {
    width: 10em;
}
.Hub_td_updated {
    text-align: center;
}

/**Hub0*/
.Hub0_th_inserted {
    width: 5em;
}
.Hub0_td_inserted {
    text-align: center;
}

.Hub0_th_subcat {
    width: 5em;
}
.Hub0_td_subcat {
    text-align: center;
    padding-left: 10px;
} 

.Hub0_th_SKU {
    width: 7em;
}
.Hub0_td_SKU {
    text-align: center;
}

.Hub0_th_spec1 {
    width: 13em;
}
.Hub0_td_spec1 {
    padding-left: 10px;
}

.Hub0_th_spec2 {
    width: 13em;
}
.Hub0_td_spec2 {
    padding-left: 10px;
}

.Hub0_th_spec3 {
    width: 13em;
}
.Hub0_td_spec3 {
    padding-left: 10px;
}

.Hub0_th_qty {
    width: 4em;
}
.Hub0_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Hub0_th_unit {
    width: 4em;
}
.Hub0_td_unit {
    text-align: left;
    padding-left: 10px;
}
