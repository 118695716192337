@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

.container {
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: black;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    filter: invert(1) contrast(2);
    width: 15%;
    margin-top: 15%;
    margin-bottom: 15%;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formRow {
    margin-bottom: 10%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.formRowIcon {
    width: 10%;
}

.formRowInput {
    margin-left: 1px;

    height: 20px;
    width: 220px;
    background-color: white;

    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    color: black;
    font-weight: 700;
}

.formLoginButton {
    margin-top: 2%;
    background-color: transparent;
    color: rgb(0, 225, 255);
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    font-size: 21px;
}

.formLoginButton:hover{
    color: rgb(255, 196, 0);
    /*font-size: 23px;*/
}

.formLoginButton:hover:before{
    /*background-color: rgb(255, 196, 0);*//*rgb(0, 140, 255);*/
    color: rgb(255, 196, 0);/*white;*/
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.formLoginButton:hover:after{
    color: rgb(255, 196, 0);
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.signUpButton {
    margin-top: 3%;
    background-color: transparent;
    color: rgb(0, 225, 255);
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 15px;
}

.signUpButton:hover{
    color: rgb(255, 196, 0);
    /*font-size: 20px;*/
}


.signUpButton:hover:before{
    /*background-color: rgb(255, 196, 0);*//*rgb(0, 140, 255);*/
    color: rgb(255, 196, 0);/*white;*/
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.signUpButton:hover:after{
    color: rgb(255, 196, 0);
    content:"-";
    font-weight: 400;
    font-size: 16px;
}

.formForgotPasswordContainer {
    position: absolute;
    bottom: 16px;
    color: rgb(0, 225, 255);
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 12px;
}

.formForgotPasswordContainer:hover {
    color: rgb(255, 196, 0);
}
