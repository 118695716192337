.CcbH_th_time {
    width: 7em
}
.CcbH_td_time {
    width: 7em
}
.CcbH_th_name {
    width: 5em;
}
.CcbH_td_name {
    text-align: center;
}

.CcbH_th_spot {
    width: 9em;
}
.CcbH_td_spot {
    text-align: center;
}

.CcbH_th_shelf {
    width: 4em;
}
.CcbH_td_shelf {
    text-align: center;
}

.CcbH_th_floor {
    width: 4em;
}
.CcbH_td_floor {
    text-align: center;
}
.CcbH_th_SKU10 {
    width: 7em;
}
.CcbH_td_SKU10 {
    text-align: center;
}

.CcbH_th_qty {
    width: 4em;
}
.CcbH_td_qty {
    text-align: right;
    padding-right: 10px;
}

.CcbH_th_unit {
    width: 4em;
}
.CcbH_td_unit {
    text-align: left;
    padding-left: 10px;
}