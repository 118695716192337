.SEARCH_master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;  
}

/*.SEARCH_table {
    margin-top: 10px;
    margin-bottom: 10px;
}*/

.PWA_master_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;  
}

.qrPWAimg {
    height: calc( 80% - 20px);
    width: calc( 80% - 20px);
}
/**/
.SEARCH_th_SKU10 {
    width: 7em;
}
.SEARCH_td_SKU10 {
    text-align: center;
}

.SEARCH_th_cat {
    width: 4em;
}
.SEARCH_td_cat {
    text-align: center;
}

.SEARCH_th_brand {
    width: 6em;
}
.SEARCH_td_brand {
    text-align: center;
}

.SEARCH_th_spec1 {
    width: 13em;
}
.SEARCH_td_spec1 {
    padding-left: 10px;
}

.SEARCH_th_spec1 {
    width: 13em;
}
.SEARCH_td_spec1 {
    padding-left: 10px;
}

.SEARCH_th_spec2 {
    width: 13em;
}
.SEARCH_td_spec2 {
    padding-left: 10px;
}

.SEARCH_th_spec3 {
    width: 13em;
}
.SEARCH_td_spec3 {
    padding-left: 10px;
}

.SEARCH_th_qty {
    width: 4em;
}
.SEARCH_td_qty {
    text-align: right;
    padding-right: 10px;
}
.SEARCH_th_unit {
    width: 4em;
}
.SEARCH_td_unit {
    text-align: left;
    padding-left: 10px;
}