.Tjqd_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Tjqd_table {
    width: 100%;
}

.Tjqd_td_qty {
    text-align: right;
}