.container {
  height: 100vh;
  min-width: 1111px;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(24, 1fr [col]);
  grid-template-rows:  repeat(23, 1fr [row]) 1.2fr [bot-row];/*repeat(12, 1fr [row]);*/
  grid-template-areas:
  "A A A A A A A A A A A A A A A A A A A A F F F F" 
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "B B B B B B B B B B B B B B B B B B B B B B B B"
  "C C C C C C D D D D D E E E E E E E E E E E E E"
  ;
  
}

.panel-A {
  grid-area: A;
  background-color: white;

  /*for children components*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.panel-B {
  grid-area: B;
  background-color: rgb(255, 255, 255);
}

.panel-C {
  grid-area: C;
  background-color: black; /*rgb(180, 180, 180);*/
  /*for children components*/
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.panel-D { /*panel menu*/
  grid-area: D;
  background-color: rgb(0, 0, 0);
  /*for children components
  display: grid;
  grid-auto-flow: column;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.panel-E { /*panel search*/
  grid-area: E;
  background-color: black;/*rgb(138, 138, 138);*/
}

.panel-F {
  grid-area: F;
  background-color: white;
}

.tinyBtnIcon {
  background-color: transparent;
  padding: 0;
  color: black;
}
.tinyBtnIcon:hover {
  color: rgb(255, 153, 0);
}

.historyBtnIcon {
  z-index: 9;
  background-color: transparent;
  color: grey;

}

.historyBtnIcon:hover {
  color: black;
}