@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

/*action buttons*/
button {
    cursor: pointer; /* Pointer/hand icon */
    border: none;
}

.faBtnCheck {
    background-color: white;
    border-color: white;

    box-shadow: white;
    
}

.faBtnBug {
    background-color: white;
    border-color: white;
}

.faBtnCircleNotch{
    background-color: grey;
    border-color: grey;

}

.aplBtnEdit {
    background-color: rgb(241, 226, 13);
    border-color: rgb(241, 226, 13);
}

.aplBtnSave {
    background-color: green;
    border-color: green;

    color: white;
}

.aplBtnAbort {
    background-color: grey;
    color: white;
}

.aplBtnDelete {
    background-color: red;
    border-color: red;

    color: white;
}

.aplBtnUpdate {
    background-color: rgb(255, 166, 0);
    border-color: rgb(255, 166, 0);

    color: white;
}

.aplBtnInsert {
    background-color: green;
    border-color: green;

    color: white;
}