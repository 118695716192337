.Cgb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Cgb_table {
    width: 100%;
}

.Cgb_th_expander {
    width: 1em;
}
.Cgb_th_shipment {
    width: 1em;
}
.Cgb_th_status {
    width: 5em;
}
.Cgb_th_qty {
    width: 5em;
}
.Cgb_th_cpu {
    width: 5em;
}
.Cgb_th_ccy {
    width: 3em;
}
.Cgb_th_cvr {
    width: 5em;
}

.Cgb_th_supplier {
    width: 5em;
}
.Cgb_th_inserted {
    width: 5em;
}
.Cgb_th_actions {
    width: 5em;
}


.Cgb_td_shipment {
    text-align: center;
}
.Cgb_td_status {
    text-align: center;
}
.Cgb_td_cat {
    text-align: center;
}
.Cgb_td_subcat {
    text-align: center;
}
.Cgb_td_SKU {
    text-align: center;
}
.Cgb_td_qty {
    text-align: right;
}
.Cgb_td_inserted {
    text-align: center;
}
.Cgb_td_updated {
    text-align: center;
}
.Cgb_td_cpu {
    text-align: right;
}
.Cgb_td_ccy {
    text-align: center;
}
.Cgb_td_cvr {
    text-align: right;
}
.Cgb_td_total {
    text-align: right;
}
.Cgb_td_supplier {
    text-align: center;
}