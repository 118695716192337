.Xzsp_container {
    height: 100%;
    width: 100%;
    /*for children components*/
    display: flex;
    flex-direction: row;
}
.Xzsp_container0 {
    height: 100%;
    width: 30%;
    padding-left: 1%;
    padding-right: 1%;

    overflow-x: auto;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Xzsp_container1 {
    height: 100%;
    width: 68%;

    overflow-x: auto;
    overflow-y: auto;
}

.Xzsp_th_SKU04 {
    width: 4em;
}

.Xzsp_td_SKU04 {
    text-align: center;
}

.Xzsp_th_SKU10s {
    width: 3em;
}

.Xzsp_td_SKU10s {
    text-align: right;
    padding-right: 10px;
}

.Xzsp_th_cat {
    width: 4em;
}

.Xzsp_td_cat {
    text-align: center;
}

.Xzsp_th_subcat {
    width: 10em;
}

.Xzsp_td_subcat {
    text-align: center;
}

.Xzsp_th_brand {
    width: 5em;
}

.Xzsp_td_brand {
    text-align: center;
}

.Xzsp_th_title {
    width: 25em;
}

.Xzsp_td_title {
    text-align: left;
    padding-left: 10px;
}

.Xzsp_th_spec1s	 {
    width: 4em;
}

.Xzsp_td_spec1s {
    text-align: right;
    padding-right: 10px;
}

.Xzsp_th_spec2s	 {
    width: 4em;
}

.Xzsp_td_spec2s {
    text-align: right;
    padding-right: 10px;
}

.Xzsp_th_spec3s	 {
    width: 4em;
}

.Xzsp_td_spec3s {
    text-align: right;
    padding-right: 10px;
}

/**/
.Xzsp0_td_id {
    text-align: center;
    padding-left: 10px;
}

.Xzsp0_th_SKU10 {
    width: 7em;
}

.Xzsp0_td_SKU10 {
    text-align: center;
}

.Xzsp0_th_spec1 {
    width: 15em;
}

.Xzsp0_td_spec1 {
    text-align: left;
    padding-left: 10px;
}

.Xzsp0_th_spec2 {
    width: 15em;
}

.Xzsp0_td_spec2 {
    text-align: left;
    padding-left: 10px;
}

.Xzsp0_th_qty {
    width: 4em;
}

.Xzsp0_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Xzsp0_th_ordered {
    width: 4em;
}

.Xzsp0_td_ordered {
    text-align: right;
    padding-right: 10px;
}

.Xzsp0_th_unit {
    width: 4em;
}

.Xzsp0_td_unit {
    text-align: left;
    padding-left: 10px;
}

.Xzsp0_th_msrp {
    width: 4em;
}

.Xzsp0_td_msrp {
    text-align: right;
    padding-right: 10px;
}

.Xzsp0_th_keywords {
}

.Xzsp0_td_keywords {
    text-align: left;
    padding-left: 10px;
}

/**/
@counter-style star {
    system: cyclic;
    symbols: "\1F48E";
    suffix: " ";
}