/*@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');*/
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.form_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form_column {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.font {
    font-family: Inconsolata, monospace;
    font-size: 16px;
}

.sku34 {
    width: 1em;
}
.sku567890 {
    width: 3em;
}
.keywords {
    width: 100%;
}

.form_confirm {
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;

    width: calc(100% - 20px);
}

/**NOTEPAD**/
.notepad {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.textarea {
    height: calc(100% - 17px);
    width: calc(100% - 4px);
    resize: none;
    border: none;
}
.keywordsList {
    width: 100%;
}
.footer {
    height: 17px;
    width: 100%;
    border-top: black solid 1px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}