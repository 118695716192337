@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

.container {
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 80px;
}

.iconButton {
    background-color: transparent;
    font-size: 50px;
}

.iconName {
    max-width: 70px;
    display: inline-block;
    word-wrap: break-word;

    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
}