.master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}
.Ckzb_th_expander,
.Ckzb0_th_expander,
.Ckzb1_th_expander,
.Ckzb2_th_expander,
.Ckzb3_th_expander {
    width: 1em;  
}
/**parent*/
.Ckzb_th_spot {
    width: 9em;
}
.Ckzb_td_spot {
    text-align: center;
}
.Ckzb_th_shelfs {
    width: 5em;
}
.Ckzb_td_shelfs {
    text-align: right;
    padding-right: 10px;
}
/**child0*/
.Ckzb0_th_shelf {
    width: 9em;
}
.Ckzb0_td_shelf {
    text-align: center;
}
.Ckzb0_th_floors {
    width: 5em;
}
.Ckzb0_td_floors {
    text-align: right;
    padding-right: 10px;
}
/*child1*/
.Ckzb1_th_floor {
    width: 9em;
}
.Ckzb1_td_floor {
    text-align: center;
}

.Ckzb1_th_holders {
    width: 5em;
}
.Ckzb1_td_holders {
    text-align: right;
    padding-right: 10px;
}
/*child2*/
.Ckzb2_th_name {
    width: 9em;
}
.Ckzb2_td_name {
    text-align: center;
}
/*child3*/
.Ckzb3_th_SKU10 {
    width: 7em;
}
.Ckzb3_td_SKU10 {
    text-align: center;
}

.Ckzb3_th_spec1 {
    width: 13em;
}
.Ckzb3_td_spec1 {
    padding-left: 10px;
}

.Ckzb3_th_spec2 {
    width: 13em;
}
.Ckzb3_td_spec2 {
    padding-left: 10px;
}

.Ckzb3_th_spec3 {
    width: 13em;
}
.Ckzb3_td_spec3 {
    padding-left: 10px;
}

.Ckzb3_th_qty {
    width: 4em;
}
.Ckzb3_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Ckzb3_th_unit {
    width: 4em;
}
.Ckzb3_td_unit {
    text-align: left;
    padding-left: 10px;
}
/**history*/
.CkzbH_th_spot {
    width: 9em;
}
.CkzbH_td_spot {
    text-align: center;
}
.CkzbH_th_shelfs {
    width: 5em;
}
.CkzbH_td_shelfs {
    text-align: right;
    padding-right: 10px;
}