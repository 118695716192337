@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
/*toggle*/
.toggler {
    border: none;
    /*background: rgba(255, 255, 255, 0);*/
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    font-weight: bold;
    background-color: black;
    color: white
}
/*keep header row top*/
.th_in_thead {
    position: sticky; 
    top: 0px;
    background: rgb(0, 0, 0);
    text-align: center;
    color: white;
}
.th_in_thead_expander {
    position: sticky; 
    top: 0px;
    background: black;
    text-align: center;
}
/*keep header row bot*/
.td_in_tfoot {
    position: sticky; 
    bottom: -1px;
    background: red;
}
/*remove default borders*/
.table {
    border-collapse: collapse;
    font-family: 'Inconsolata', monospace;
    font-size: 14px;
}
/*tr in tbody lines*/
.tr_in_tbody{
    border-top: 1px solid rgb(117, 117, 117);
}
/*hover highlight*/
.tr_in_tbody:hover{
    background: rgba(217, 255, 0, 0.678);
}
.td_in_tbody:hover{
    background: rgba(0, 255, 255, 0.678);
}
/**/
.tr_in_tbody_dataAdder {
    border-top: 4px double rgb(117, 117, 117);    
}
.th_in_tbody_dataAdder {
    padding-top: 7px;
    padding-bottom: 0px;
}
/*keep footer row bot*/
.th_in_tfoot {
    position: sticky; 
    bottom: 0px;
    background: rgb(255, 255, 255);
    text-align: center;
}
.th_in_tfoot_expander {
    position: sticky; 
    bottom: 0px;
    background: black;
    text-align: center;
}
/*action buttons*/
.action_button_group {
    float: left; /* Float the buttons side by side */
}