@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

.container{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.searchContainer{
    height: 100%;
    width: 80%;

    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.searchBox{
    height: 50%;
    width: 90%;
    border: none;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-size: 1.2em;
}

.searchBtn{
    margin-left: 5px;
    height: 55%;
    width: 10%;
    border-radius: 5px;
    background-color: rgb(0, 225, 255);/*rgb(0, 140, 255);*/
    color: black;
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
}


.searchBtn:hover{
    color: white;
    background-color: rgb(255, 196, 0);/*rgb(0, 140, 255);*/
}
.functionContainer {
    height: 100%;
    width: 20%;

    padding-left: 10px;
    padding-right: 20px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
}