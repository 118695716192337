.container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: row;
}
.container_form {
    width: 30%;
    padding-left: 1%;
}
.container_book {

    width: 70%;
    overflow-x: auto;
    /*for children components*/
    display: flex;
    flex-direction: column;
    
}
/**/
.general_confirm_button {
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;
}
/**/
.font {
    font-family: Inconsolata, monospace;
    font-size: 16px;
}
/*constant*/
.hidden {
    visibility: hidden;
}
.page-A4 {
    width: 210mm;
    height: 297mm;
    margin: 5mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
/*formats*/
.safe_area-A4-2x2 {
    height: calc(100% - 20mm);
    width: calc(100% - 20mm);

    padding: 10mm;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.label-A4-2x2 {
    width: calc(1/2*100%);
    height: calc(1/2*100%);
}
.name-A4-2x2 {
    text-align: center;
    font-size: 60px;
    font-weight: 800;
    color: white;

    padding-top: 3%;
    padding-bottom: 1%;
    background-color: black;
}
.qr_code-A4-2x2 {
    display: flex;
    flex-direction: column-reverse;
    /*justify-content: center;*/
    align-items: center;
}
.qr_img-A4-2x2 {
    width: 70%;
    margin-top: 5%;
}
.qr_text-A4-2x2 {
    font-size: 28px;
    /*writing-mode: vertical-rl;*/
    transform-origin: left;
    transform: translate(40%, 0%) rotate(180deg);
}

.safe_area-A4-4x11 {
    height: calc(100% - 20mm);
    width: calc(100% - 6mm);

    padding-top: 10mm;
    padding-bottom: 10mm;
    padding-left: 3mm;
    padding-right: 3mm;

    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-content: flex-start;
}
.label-A4-4x11 {
    width: calc(1/4*100%);
    height: calc(1/11*100%);

    background-color: black;

    display: flex;
    justify-content: center;
}
.name-A4-4x11 {
    text-align: center;
    writing-mode: vertical-rl;
    font-weight: 30;
    font-size: 22px;
    color: white;

    background-color: black;
}
.qr_code-A4-4x11 {
    display: flex;
    flex-direction:row-reverse;
    justify-content: center;
    align-items: center;
}
.qr_img-A4-4x11 {
    padding-left: 1%;
    padding-right: 3%;
}
.qr_text-A4-4x11 {
    font-size: 14px;
    writing-mode: vertical-rl;
    color: white;
}

/*for printer*/
@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .page-A4 {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
    .panel-A,
    .panel-C,
    .panel-D,
    .panel-E,
    .panel-F,
    .panel-B {
        display: none !important;
    }
    .Lybq_page-A4__3HJG {
        display: block !important;
    }
}