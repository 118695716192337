@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

.avatarContainer {
    /*bot bar is roughly 47px*/
    width: 35px;
    height: 35px;
    padding-left: 20px;
    /*for children components*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.avatar {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.userActionsContainer {
    padding-left: 10px;
    /*for children components*/
    display: flex;
    flex-direction: column;
}

.userDisplayname {
    color: white;
    font-size: 11pt;
}

.logOut {
    color: white;
    font-size: 9pt;
}
  