.master_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}
/**parent*/
.Spzb_th_SKU04 {
    width: 5em;
}
.Spzb_td_SKU04 {
    text-align: center;
}

.Spzb_th_SKU10s {
    width: 2em;
}
.Spzb_td_SKU10s {
    text-align: right;
    padding-right: 10px;
}

.Spzb_th_cat {
    width: 2em;
}
.Spzb_td_cat {
    text-align: center;
}

.Spzb_th_subcat {
    width: 9em;
}
.Spzb_td_subcat {
    text-align: center;
}

.Spzb_th_brand {
    width: 9em;
}
.Spzb_td_brand {
    text-align: center;
}

.Spzb_td_title {
    padding-left: 10px;
}

.Spzb_th_spec1s {
    width: 4em;
}
.Spzb_td_spec1s {
    text-align: right;
    padding-right: 10px;
}

.Spzb_th_spec2s {
    width: 4em;
}
.Spzb_td_spec2s {
    text-align: right;
    padding-right: 10px;
}

.Spzb_th_spec3s {
    width: 4em;
}
.Spzb_td_spec3s {
    text-align: right;
    padding-right: 10px;
}

/**Spzb0*/
.Spzb0_th_SKU10 {
    width: 8em;
}
.Spzb0_td_SKU10 {
    text-align: center;
}

.Spzb0_th_spec1 {
    width: 13em;
}
.Spzb0_td_spec1 {
    padding-left: 10px;
}

.Spzb0_th_spec2 {
    width: 13em;
}
.Spzb0_td_spec2 {
    padding-left: 10px;
}

.Spzb0_th_spec3 {
    width: 13em;
}
.Spzb0_td_spec3 {
    padding-left: 10px;
}

.Spzb0_th_qty {
    width: 4em;
}
.Spzb0_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Spzb0_th_ordered {
    width: 4em;
}
.Spzb0_td_ordered {
    text-align: right;
    padding-right: 10px;
}

.Spzb0_th_unit {
    width: 4em;
}
.Spzb0_td_unit {
    text-align: left;
    padding-left: 10px;
}

.Spzb0_th_msrp {
}
.Spzb0_td_msrp{
    text-align: right;
    padding-right: 10px;
}
.Spzb0_th_keywords {
}
.Spzb0_td_keywords {
    text-align: right;
    padding-right: 10px;
}
/*Spzb1*/
.Spzb1_th_name {
    width: 5em;
}
.Spzb1_td_name {
    text-align: center;
}

.Spzb1_th_spot {
    width: 9em;
}
.Spzb1_td_spot {
    text-align: center;
}

.Spzb1_th_shelf {
    width: 4em;
}
.Spzb1_td_shelf {
    text-align: center;
}

.Spzb1_th_floor {
    width: 4em;
}
.Spzb1_td_floor {
    text-align: center;
}

/**Spzb2*/
.Spzb2_th_SKU10 {
    width: 7em;
}
.Spzb2_td_SKU10 {
    text-align: center;
}

.Spzb2_th_spec1 {
    width: 13em;
}
.Spzb2_td_spec1 {
    padding-left: 10px;
}

.Spzb2_th_spec2 {
    width: 13em;
}
.Spzb2_td_spec2 {
    padding-left: 10px;
}

.Spzb2_th_spec3 {
    width: 13em;
}
.Spzb2_td_spec3 {
    padding-left: 10px;
}

.Spzb2_th_qty {
    width: 4em;
}
.Spzb2_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Spzb2_th_ordered {
    width: 4em;
}
.Spzb2_td_ordered {
    text-align: right;
    padding-right: 10px;
}

.Spzb2_th_unit {
    width: 4em;
}
.Spzb2_td_unit {
    text-align: left;
    padding-left: 10px;
}