/*@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

.container{
    /*height: 25vh;
    width: 25vw;*/
    background-color: white;/*trans black -> rgba(0, 0, 0, 0.685);*/
    border-style: solid;
    border-width: 3px;
    border-color: black;
    border-radius: 12px 12px 0px 0px;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.windowBar{
    background-color: rgb(0, 0, 0);
    height: 20px;
    padding-right: 5px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
}

.windowDragBar{
    z-index: 9;
    background-color: rgba(255, 0, 0, 0);
    height: 18px;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}
.windowTitlePrefix{
    margin-left: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 700;
    color: rgb(0, 225, 255);
}
.windowTitle{
    margin-left: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    color: white;
}
.windowBtn{
    background-color: transparent;
    padding: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.windowContent{
    /*height: 100%;*/
    width: 100%;
    height: 100%
}

.exitBtn{
    font-size: 18px;
}

.resizeBtn{
    position: absolute;
    bottom: 5px;
    right: 3px;
    font-size: 12px;
}
.resizeBtn:hover{
    background-color: rgb(255, 255, 255);
    transform-origin: center;
    transform: scale(1.5);
}

.minimizeBtn{
    width: 11.7px
}