.Dcb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Dcb_table {
    /*width: 100%;*/
}

.Dcb_th_expander {
    width: 1em;
}
/**/
.Dcb_th_status {
    width: 5em;
}
.Dcb_td_status {
    text-align: center;
}

.Dcb_th_batch_code {
    width: 5em;
}
.Dcb_td_batch_code {
    text-align: center;
}

.Dcb_th_origin {
    width: 7em;
}
.Dcb_td_origin {
    text-align: center;
}

.Dcb_th_departure {
    width: 5em;
}
.Dcb_td_departure {
    text-align: center;
}

.Dcb_th_est_arrival {
    width: 5em;
}
.Dcb_td_est_arrival {
    text-align: center;
}

.Dcb_th_shippingFee {
    width: 7em;
}
.Dcb_td_shippingFee {
    text-align: right;
}

.Dcb_th_ccy {
    width: 2em;
}
.Dcb_td_ccy {
    text-align: left;
}

.Dcb_th_cvr {
    width: 7em;
}
.Dcb_td_cvr {
    text-align: right;
}

.Dcb_th_tariff {
    width: 7em;
}
.Dcb_td_tariff {
    text-align: right;
}

.Dcb_th_inserted {
    width: 10em;
}
.Dcb_td_inserted {
    text-align: center;
}

.Dcb_th_updated {
    width: 10em;
}
.Dcb_td_updated {
    text-align: center;
}
/**/
.Dcb0_th_SKU {
    width: 7em;
}
.Dcb0_td_SKU {
    text-align: center;
}

.Dcb0_td_subcat {
    text-align: center;
}

.Dcb0_th_brand {
    width: 5em;
}

.Dcb0_td_brand {
    text-align: center;
}

.Dcb0_th_title {
    width: 25em;
}

.Dcb0_th_spec1 {
    width: 13em;
}
.Dcb0_td_spec1 {
    padding-left: 10px;
}

.Dcb0_th_spec2 {
    width: 13em;
}
.Dcb0_td_spec2 {
    padding-left: 10px;
}

.Dcb0_th_spec3 {
    width: 13em;
}
.Dcb0_td_spec3 {
    padding-left: 10px;
}

.Dcb0_th_purchased	 {
    width: 4em;
}
.Dcb0_td_purchased	 {
    text-align: right;
    padding-right: 10px;
}

.Dcb0_th_stashing	 {
    width: 4em;
}
.Dcb0_td_stashing	 {
    text-align: right;
    padding-right: 10px;
}

.Dcb0_th_stashed	 {
    width: 4em;
}
.Dcb0_td_stashed	 {
    text-align: right;
    padding-right: 10px;
}

.Dcb0_th_unit {
    width: 4em;
}
.Dcb0_td_unit {
    text-align: left;
    padding-left: 10px;
}
/**/
.DcbH_th_status {
    width: 5em;
}
.DcbH_td_status {
    text-align: center;
}

.DcbH_th_batch_code {
    width: 5em;
}
.DcbH_td_batch_code {
    text-align: center;
}

.DcbH_th_origin {
    width: 7em;
}
.DcbH_td_origin {
    text-align: center;
}

.DcbH_th_departure {
    width: 5em;
}
.DcbH_td_departure {
    text-align: center;
}

.DcbH_th_est_arrival {
    width: 5em;
}
.DcbH_td_est_arrival {
    text-align: center;
}

.DcbH_th_shippingFee {
    width: 7em;
}
.DcbH_td_shippingFee {
    text-align: right;
}

.DcbH_th_ccy {
    width: 2em;
}
.DcbH_td_ccy {
    text-align: left;
}

.DcbH_th_cvr {
    width: 7em;
}
.DcbH_td_cvr {
    text-align: right;
}

.DcbH_th_tariff {
    width: 7em;
}
.DcbH_td_tariff {
    text-align: right;
}

.DcbH_th_inserted {
    width: 10em;
}
.DcbH_td_inserted {
    text-align: center;
}

.DcbH_th_updated {
    width: 10em;
}
.DcbH_td_updated {
    text-align: center;
}