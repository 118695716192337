.Huqd_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.Huqd_table {
    /*width: 100%;*/
}

.Huqd_th_expander {
    width: 1em;
}
/**/
.Huqd_th_status {
    width: 5em;
}
.Huqd_td_status {
    text-align: center;
}

.Huqd_th_batch_code {
    width: 5em;
}
.Huqd_td_batch_code {
    text-align: center;
}

.Huqd_th_origin {
    width: 7em;
}
.Huqd_td_origin {
    text-align: center;
}

.Huqd_th_departure {
    width: 5em;
}
.Huqd_td_departure {
    text-align: center;
}

.Huqd_th_est_arrival {
    width: 5em;
}
.Huqd_td_est_arrival {
    text-align: center;
}

.Huqd_th_shippingFee {
    width: 7em;
}
.Huqd_td_shippingFee {
    text-align: right;
}

.Huqd_th_ccy {
    width: 2em;
}
.Huqd_td_ccy {
    text-align: left;
}

.Huqd_th_cvr {
    width: 7em;
}
.Huqd_td_cvr {
    text-align: right;
}

.Huqd_th_tariff {
    width: 7em;
}
.Huqd_td_tariff {
    text-align: right;
}

.Huqd_th_inserted {
    width: 10em;
}
.Huqd_td_inserted {
    text-align: center;
}

.Huqd_th_updated {
    width: 10em;
}
.Huqd_td_updated {
    text-align: center;
}
/**/
.Huqd0_th_SKU {
    width: 7em;
}
.Huqd0_td_SKU {
    text-align: center;
}

.Huqd0_td_subcat {
    text-align: center;
}

.Huqd0_th_brand {
    width: 5em;
}

.Huqd0_td_brand {
    text-align: center;
}

.Huqd0_th_title {
    width: 25em;
}

.Huqd0_th_spec1 {
    width: 13em;
}
.Huqd0_td_spec1 {
    padding-left: 10px;
}

.Huqd0_th_spec2 {
    width: 13em;
}
.Huqd0_td_spec2 {
    padding-left: 10px;
}

.Huqd0_th_spec3 {
    width: 13em;
}
.Huqd0_td_spec3 {
    padding-left: 10px;
}

.Huqd0_th_purchased	 {
    width: 4em;
}
.Huqd0_td_purchased	 {
    text-align: right;
    padding-right: 10px;
}

.Huqd0_th_stashing	 {
    width: 4em;
}
.Huqd0_td_stashing	 {
    text-align: right;
    padding-right: 10px;
}

.Huqd0_th_stashed	 {
    width: 4em;
}
.Huqd0_td_stashed	 {
    text-align: right;
    padding-right: 10px;
}

.Huqd0_th_unit {
    width: 4em;
}
.Huqd0_td_unit {
    text-align: left;
    padding-left: 10px;
}