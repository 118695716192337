.Rcb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: row;
}
.Rcb_confirm_button {
    width: calc(100% - 20px);
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;
}
.Rcb_container_0 {
    height: 100%;
    width: 50%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Rcb_container_1 {
    height: 100%;
    width: 50%;
    overflow-x: auto;
    overflow-y: auto;
}
.Rcb_table {
    width: 100%;
}

.Rcb_th_expander {
    width: 1em;
}
/**/
.Rcb_th_status {
    width: 5em;
}
.Rcb_td_status {
    text-align: center;
}

.Rcb_th_batch_code {
    width: 5em;
}
.Rcb_td_batch_code {
    text-align: center;
}

.Rcb_th_origin {
    width: 7em;
}
.Rcb_td_origin {
    text-align: center;
}

.Rcb_th_departure {
    width: 5em;
}
.Rcb_td_departure {
    text-align: center;
}

.Rcb_th_est_arrival {
    width: 5em;
}
.Rcb_td_est_arrival {
    text-align: center;
}

.Rcb_th_shippingFee {
    width: 7em;
}
.Rcb_td_shippingFee {
    text-align: right;
}

.Rcb_th_ccy {
    width: 2em;
}
.Rcb_td_ccy {
    text-align: left;
}

.Rcb_th_cvr {
    width: 7em;
}
.Rcb_td_cvr {
    text-align: right;
}

.Rcb_th_tariff {
    width: 7em;
}
.Rcb_td_tariff {
    text-align: right;
}

.Rcb_th_inserted {
    width: 10em;
}
.Rcb_td_inserted {
    text-align: center;
}

.Rcb_th_updated {
    width: 10em;
}
.Rcb_td_updated {
    text-align: center;
}