.Ccb_confirm_button {
    color: white;
    background-color: green;
    margin: 10px;
    font-weight: 700;
}
.Ccb_container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    /*for children components*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.Ccb_table {
    width: 100%;
}

.Ccb_th_expander {
    width: 1em;
}
/**/
.Ccb_th_spot {
    width: 9em;
}
.Ccb_td_spot {
    text-align: center;
}

.Ccb_th_shelf {
    width: 4em;
}
.Ccb_td_shelf {
    text-align: center;
}

.Ccb_th_floor {
    width: 4em;
}
.Ccb_td_floor {
    text-align: center;
}
.Ccb_th_name {
    width: 5em;
}
.Ccb_td_name {
    text-align: center;
}

.Ccb_th_SKU10 {
    width: 7em;
}
.Ccb_td_SKU10 {
    text-align: center;
}

.Ccb_th_spec1 {
    width: 13em;
}
.Ccb_td_spec1 {
    padding-left: 10px;
}

.Ccb_th_spec2 {
    width: 13em;
}
.Ccb_td_spec2 {
    padding-left: 10px;
}

.Ccb_th_spec3 {
    width: 13em;
}
.Ccb_td_spec3 {
    padding-left: 10px;
}

.Ccb_th_qty {
    width: 4em;
}
.Ccb_td_qty {
    text-align: right;
    padding-right: 10px;
}

.Ccb_th_unit {
    width: 4em;
}
.Ccb_td_unit {
    text-align: left;
    padding-left: 10px;
}