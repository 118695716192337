@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&display=swap');

.master {
    margin-left: 0.3em;
    margin-right: 0.3em;
    padding: 0;
    border: none;
    background: none;

    color: white;
}

.slave {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.3em;
    padding: 0;
    border: none;
    background: none;
}

.tool {
    border: none;
    background: none;
    padding: 0;
    margin-top: 0.2em;
    
    position: relative;
    left: -1em;
}

.clicked {
    font-size: 30px;
    font-family: 'Ma Shan Zheng', cursive;  
}

.not_clicked {
    font-size: 20px;
    font-family: 'Noto Sans SC', sans-serif;
}

/**/
.addOn_active {
    position: relative;
    left: -20px;
}
.addOn_notActive {
    position: relative;
    left: -10px;
}

.addOn {
    background-color: transparent;
}

.addOnIcon_active {
    color: black;
}
.addOnIcon_notActive {
    color: grey;
}
.addOnIcon_active:hover {
    color: rgb(255, 196, 0);
}